import React from 'react';
import IntlMessage from '../components/IntlMessage';
import {
  BankOutlined,
  CalendarOutlined,
  CarOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  FileOutlined,
  HomeOutlined,
  LockOutlined,
  NodeIndexOutlined,
  SettingOutlined,
  TableOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';

const menu = [
  {
    key: '/app/home',
    label: <IntlMessage id="global.home" />,
    icon: <HomeOutlined />,
    permission: null,
    children: null,
  },

  {
    key: '/app/services',
    label: <IntlMessage id="global.services" />,
    icon: <TableOutlined />,
    permission: null,
    children: null,
  },

  {
    key: '/app/requests',
    label: "Requests",
    icon: <FileOutlined />,
    permission: null,
    children: null,
  },

  {
    key: '/app/accounts',
    label: "Accounts",
    icon: <UserOutlined />,
    permission: null,
    children: null,
  },
];

export default menu;
