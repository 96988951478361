import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Drawer, Modal, Menu } from 'antd';
import {
  LogoutOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import menu from '../menu';
import MenuGroupItem from '../components/Menu/MenuGroupItem';
import MenuItem from '../components/Menu/MenuItem';
// actions
import { logout } from '../redux/actions/AuthActions';

const { confirm } = Modal;

const AppDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const onLogout = () => {
    confirm({
      title: 'Are you sure to log out?',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => {
        dispatch(logout()).then(() => {
          navigate('/login');
        });
      },
    });
  };

  const onClickMenu = (key) => {
    if (key !== 'logout') {
      navigate(key);
    } else {
      onLogout();
    }
    onClose();
  };

  return (
    <Drawer placement="right" closable={false} onClose={onClose} open={visible}>
      <Menu
        mode="inline"
        selectedKeys={[pathname]}
        style={{ height: '100%', borderRight: 0 }}
        onClick={({ key }) => onClickMenu(key)}>
        {menu.map((item, index) => {
          if (item.children) {
            return <MenuGroupItem item={item} key={index} />;
          } else {
            return <MenuItem item={item} key={item.path || index} />;
          }
        })}
        <Menu.Item key="profile" icon={<InfoCircleOutlined />}>
          Thông tin tài khoản
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Đăng xuất
        </Menu.Item>
      </Menu>
    </Drawer>
  );
};

export default AppDrawer;
