import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import menu from '../menu';
import { supabase } from '../utils/supabaseClient';
import { FileOutlined } from '@ant-design/icons';
import { useUser } from '../utils/context';
import { LoadingScreen } from '../components/LoadingScreen';

function AppSidebar() {
  const isMount = useRef(true);
  const [menuDisplay, setMenuDisplay] = useState([]);
  const authUser = useSelector((state) => state.auth.authUser);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { user } = useUser();

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        //const { data: { user } } = await supabase.auth.getUser();

        //console.log('login data sidebar', user);

        if (user ) {
          // const { data: accountData, error } = await supabase
          //   .from('accounts')
          //   .select('user_id')
          //   .eq('user_id', user.id)
          //   .single();
          if (user?.account_role === 'provider') {
            setMenuDisplay([
              {
                key: '/app/myrequests',
                label: 'Requests',
                icon: <FileOutlined />,
                permission: null,
                children: null,
              },
            ]);
            return;
          } else if (user?.account_role === 'customer') {
            setMenuDisplay([
              {
                key: '/app/customerrequests',
                label: 'Requests',
                icon: <FileOutlined />,
                permission: null,
                children: null,
              },
            ]);
            return;
          } else if (user.data === null) {
            setMenuDisplay(menu);
          }
        }
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenuData();

    return () => {
      isMount.current = false;
    };
  }, [authUser, user]);

  const onClickMenu = (key) => {
    navigate(key);
  };

  const filterMenu = (menuItems, items) => {
    return menuItems
      .map((menuItem) => {
        const hasChildren = menuItem.children && menuItem.children.length > 0;
        let filteredChildren = null;

        if (hasChildren) {
          filteredChildren = filterMenu(menuItem.children, items);
        }

        const hasMatchingChild =
          filteredChildren && filteredChildren.length > 0;
        const hasMatchingItem = items.some(
          (item) => item.menuitem.path === menuItem.key
        );

        if (hasMatchingChild) {
          return { ...menuItem, children: filteredChildren };
        } else if (hasMatchingItem) {
          return menuItem;
        }

        return null;
      })
      .filter(Boolean);
  };

  return (
    <>
      {menuDisplay && menuDisplay.length > 0 ? (
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[pathname]}
          style={{ borderRight: 0 }}
          onClick={({ key }) => onClickMenu(key)}
          items={menuDisplay}
        />
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

export default AppSidebar;
