import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AppLayout from '../layouts';
import NotFoundPage from '../routes/error/NotFoundPage';
import { useSelector } from 'react-redux';
import { routesApp } from '../routes';

const AppRoutes = () => {
  const isMount = useRef(true);
  const [routes, setRoutes] = useState([]);
  const authUser = useSelector((state) => state.auth.authUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isMount.current) {
      // Fetch or set route data if needed
      setRoutes(routesApp);
      setLoading(false);
    }
    return () => {
      isMount.current = false;
    };
  }, [authUser]);

  if (!authUser) {
    return <Navigate to="/login" />;
  }

  return (
    <AppLayout>
      {!loading && (
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute
                  authUser={authUser}
                  permission={route.permission}
                  element={<route.component />}
                />
              }
            />
          ))}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </AppLayout>
  );
};

const ProtectedRoute = ({ authUser, permission, element }) => {
  // Add your permission logic here
  const hasPermission = true; // Replace with actual permission logic
  return hasPermission ? element : <Navigate to="/login" />;
};

export default AppRoutes;
