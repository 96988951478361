import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {
  Layout,
  Modal,
  Button,
  Avatar,
  Row,
  Col,
  Popover,
  Divider,
  Space,
  Typography,
  Input,
} from 'antd';
import {
  MenuFoldOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
// actions
import { logout } from '../redux/actions/AuthActions';
import AppDrawer from './AppDrawer';
import IntlMessages from '../components/IntlMessage';
import { useUser } from '../utils/context';

const { Text } = Typography;
const { Header } = Layout;
const { confirm } = Modal;

const AppHeader = (props) => {
  const [visible, setVisible] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);



  const {
    collapsed,
    onSiderCollapse,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.auth.authUser);
  const config = useSelector((state) => state.config);
  const { setUser } = useUser();
  const onLogout = () => {
    confirm({
      title: 'Are you sure to log out?',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => {
        setUser(null);
        dispatch(logout()).then(() => {
          navigate('/login');
        });
      },
    });
  };
  // const handleLogout = async () => {
  //   await supabase.auth.signOut();
  //   setUser(null);
  //   router.push('/login');
  // };
  const onSearch = (value) => {
   
    setVisibleDrawer(true);
  };

  const onCollapse = (value) => {
    onSiderCollapse(value);
  };

  const handleDrawerClose = () => setVisibleDrawer(false);

  return (
    <Header className="site-header-background">
      <Row justify="space-between">
        <Col>
          {/* <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => onCollapse(!collapsed)}
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            width: 64,
            height: 64,
            color:'white'
          }}
          /> */}
        </Col>
        <Col>
          <Row align="middle" justify="space-around">
            {/* <Col>
              <Input
                placeholder="Search..."
                size="large"
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: '#1890ff' }} />
                }
                onSearch={onSearch}
              />
            </Col> */}
            <Col>
              {/* <Divider type="vertical" style={{ height: '1.5em' }} /> */}
              
                <Popover
                  trigger="click"
                  className="header-actions"
                  placement="bottomRight"
                  content={
                    <>
                      <Row gutter={[20, 20]}>
                        <Col>
                          <Row align="middle" justify="space-around">
                            <Avatar style={{ }}>
                              {authUser?.lastname?.charAt(0)}
                            </Avatar>
                          </Row>
                        </Col>
                        <Col>
                          <Text strong>{`${authUser?.lastname || ''} ${
                            authUser?.firstname || ''
                          } `}</Text>
                          {/* <br /> */}
                          <Text style={{ fontSize: '12px' }} type="secondary">
                            {authUser?.email}
                          </Text>
                        </Col>
                      </Row>
                      <Divider
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      />
                      <Link to="/app/profile">
                        <Button type="link">
                          <IntlMessages id="global.account_information" />
                        </Button>
                      </Link>
                      <Divider
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      />
                      <Button type="link" onClick={onLogout}>
                        <Space>
                          <IntlMessages id="global.signout" />
                        </Space>
                      </Button>
                    </>
                  }
                  title={null}>
                  {authUser?.image ? (
                    <Avatar
                      src={`${config.url_asset_root}${authUser?.image}`}
                    />
                  ) : (
                    <Avatar style={{   }}>
                      <UserOutlined/>
                    </Avatar>
                  )}
                </Popover>
              
            </Col>
          </Row>
        </Col>
      </Row>
      <AppDrawer open={visible} onClose={() => setVisible(false)} />
     
    </Header>
  );
};

export default AppHeader;
