import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { Link } from 'react-router-dom';

/**
 * Render function for breadcrumb
 * @param {*} route
 * @param {*} params
 * @param {*} routes
 * @param {*} paths
 */
const itemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PageTitle = ({ title, routes }) => {
  const navigate = useNavigate();

  return (
    <PageHeader
      className="site-page-header"
      onBack={() => navigate(-1)}
      title={title}
      breadcrumb={{ routes: routes, itemRender: itemRender }}
    />
  );
};

export default PageTitle;
