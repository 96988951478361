// src/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, InputNumber, message, Switch, Tag, Row, Space } from 'antd';
import { supabase } from '../../utils/supabaseClient';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingService, setEditingService] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    const { data, error } = await supabase.from('services').select('*').order('title', { ascending: true });
    setLoading(false);

    if (error) {
      message.error('Failed to fetch services');
    } else {
      setServices(data);
    }
  };

  const showModal = (service) => {
    setEditingService(service);
    setIsModalVisible(true);
    if (service) {
      form.setFieldsValue(service);
    } else {
      form.resetFields();
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (editingService) {
        await updateService(values);
      } else {
        await createService(values);
      }
      setIsModalVisible(false);
      fetchServices();
    } catch (error) {
      console.error('Failed to handle form submission', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingService(null);
  };

  const createService = async (service) => {
    const user = supabase.auth.getUser();
    const { error } = await supabase.from('services').insert([{ ...service }]);
    if (error) {
      message.error('Failed to create service');
    } else {
      message.success('Service created successfully');
    }
  };

  const updateService = async (service) => {
    const { error } = await supabase.from('services').update(service).eq('id', service.id);
    if (error) {
      message.error('Failed to update service');
    } else {
      message.success('Service updated successfully');
    }
  };

  const deleteService = async (id) => {
    const { error } = await supabase.from('services').delete().eq('id', id);
    if (error) {
      message.error('Failed to delete service');
    } else {
      message.success('Service deleted successfully');
      fetchServices();
    }
  };

 

  const columns = [
    { title: 'Title', dataIndex: 'title', key: 'title' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status ? 'green' : 'red'}>
          {status ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Price', dataIndex: 'price', key: 'price',
      
      render: (_, record) => (
        <Space direction='vertical'><Row>{record.price}USD</Row>
          {record.payment_link != null  && (<Row><a href={record.payment_link} target='_blank'>Payment Link</a> </Row>)}
        </Space>
      ),

     },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => showModal(record)}>Edit</Button>
          <Button type="link" danger onClick={() => deleteService(record.id)}>Delete</Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <h1>Service Type</h1>
      <Button type="primary" onClick={() => showModal(null)}>Add Service</Button>
     
      <Table columns={columns} dataSource={services} loading={loading} rowKey="id" />

      <Modal title={editingService ? 'Edit Service' : 'Add Service'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input />
          </Form.Item>
          <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Please input the price' }]}>
            <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item name="payment_link" label="Payment link">
            <Input />
          </Form.Item>

          <Form.Item name="status" label="Status" valuePropName="checked">
            <Switch />
          </Form.Item>
          {editingService && <Form.Item name="id" hidden><Input /></Form.Item>}
        </Form>
      </Modal>
    </div>
  );
};

export default Services;
