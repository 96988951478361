// src/Accounts.js
import React, { useEffect, useState } from 'react';
import { Table, Button, message, Modal } from 'antd';
import { fetchAccounts, fetchAccountsWithUsers } from '../../api/api';
import AccountForm from './CreateAccount';

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    loadAccounts();
  }, []);

  const loadAccounts = async () => {
    try {
      const data = await fetchAccountsWithUsers();
      console.log('account data',data);
      setAccounts(data);
    } catch (error) {
      message.error('Failed to fetch accounts');
    } finally {
      setLoading(false);
    }
  };

  const handleAccountCreated = () => {
    loadAccounts();
    setIsModalVisible(false);
  };

  const columns = [
    { title: 'First Name', dataIndex: 'firstname', key: 'firstname' },
    { title: 'Last Name', dataIndex: 'lastname', key: 'lastname' },
    { title: 'Title', dataIndex: 'title', key: 'title' },
    {
      title: 'Email', dataIndex: 'email', key: 'email',
      render: (text, record) => {
        return record?.email;

      }
    },
    { title: 'Role', dataIndex: 'role', key: 'role' },
    { title: 'Created At', dataIndex: 'created_at', key: 'created_at', render: (text) => new Date(text).toLocaleString() },
  ];

  return (
    <div>
      <h1>Accounts</h1>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>Create Account</Button>
      <Table columns={columns} dataSource={accounts} loading={loading} rowKey="id" />

      <Modal title="Create Account" visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
        <AccountForm onAccountSaved={handleAccountCreated} />
      </Modal>
    </div>
  );
};

export default Accounts;
