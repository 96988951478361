import React, { useEffect, useRef, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Login from '../routes/auth/Login';
import AppRoutes from './AppRoutes';
import ResetPassword from '../routes/auth/ResetPassword';
import { useDispatch } from 'react-redux';
import { getAuthUser } from '../redux/actions/AuthActions';
import { LoadingScreen } from '../components/LoadingScreen';
import { supabase } from '../utils/supabaseClient';
import { LOGIN } from '../redux/types';
import { useUser } from '../utils/context';

function MainApp() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isMount = useRef(false);
  const [userOld, setUserOld] = useState(null);

  const { setUser } = useUser();

  const navigate = useNavigate();
  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error getting session:', error);
        navigate(`/login${location.search || ''}`);
      } else if (data?.session) {
        dispatch({ type: LOGIN, payload: data.session.user });
        setUserOld(data.session.user);
        setLoading(false);

        // if (data.session.user ) {
          const { data: accountData, error } = await supabase
            .from('accounts')
            .select('id,user_id,role')
            .eq('user_id', data.session.user.id)
            .maybeSingle();

          console.log('accountData login main app', accountData);
          if (data.session.user) {
            //setUser(data.user); // Store user in context

            if (accountData) {
              setUser({
                ...data.session.user,
                account_role: accountData.role,
                account_id: accountData.id,
              });
            } else
              setUser({
                data: null,
              });
            //console.log('User logged in:', data.user);
          }
        
      } else {
        navigate(`/login${location.search || ''}`);
      }
      setLoading(false);
    };

    getUser();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (session) {
          dispatch({ type: LOGIN, payload: session.user });
          setUserOld(session.user);
        } else {
          navigate(`/login${location.search || ''}`);
        }
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [dispatch, navigate, setUser]);

  if (loading) {
    return <LoadingScreen />;
  }
  if (location.pathname === '/') {
    return <Navigate to="/app/home" />;
  }

  return (
    <React.Fragment>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/app/*" element={<AppRoutes />} />
      </Routes>
    </React.Fragment>
  );
}

export default MainApp;
