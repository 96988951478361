import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Input, Card, Row, Col, Modal, Typography } from 'antd';
import { LockTwoTone, MailTwoTone } from '@ant-design/icons';
import IntlMessages from '../../components/IntlMessage';
import logo from '../../assets/img/logo_full.png';
import { supabase } from '../../utils/supabaseClient';
import { NotificationManager } from 'react-notifications';
import { LOGIN } from '../../redux/types';
import { useUser } from '../../utils/context';

const { Title, Text, Paragraph } = Typography;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
  const [signUpRole, setSignUpRole] = useState(null); // Store the selected role for signup
  const authUser = useSelector((state) => state.auth.authUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useUser();

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password,
      });

      if (error) {
        NotificationManager.error(error.message);
        setLoading(false);
        return;
      }

      if (data) {
        const { user } = data.session;

        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .select('user_id, role')
          .eq('user_id', user.id)
          .maybeSingle();

        if (accountError) {
          NotificationManager.error(accountError.message);
          setLoading(false);
          return;
        }

        if (accountData) {
          setUser({ ...user, account_role: accountData.role, account_id: accountData.id });
        }

        dispatch({ type: LOGIN, payload: data.session.user });

        if (accountData.role === 'provider') {
          navigate('/app/myrequests');
        } else if (accountData.role === 'customer') {
          navigate('/app/customerrequests');
        }
      }
    } catch (err) {
      console.error('Login error:', err);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Form submission failed:', errorInfo);
  };

  // Sign-up function for creating provider or customer account
  const handleSignUp = async (values) => {
    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signUp({
        email: values.email,
        password: values.password,
        options: {
          data: {
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            role: signUpRole, // Set the role based on the button clicked
          },
        },
      });

      if (error) {
        NotificationManager.error(error.message);
      } else {
        const { user } = data;
        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .insert([
            {
              user_id: user.id,
              role: signUpRole, // Use the selected role
              firstname: values.first_name,
              lastname: values.last_name,
              phone: values.phone,
              email: values.email,
            },
          ])
          .select('id')  // Return the id of the inserted account
          .single();     // Get the single inserted row

        if (accountData) {
          const account_id = accountData.id;

          // Set the user context with account_id
          setUser({ ...user, account_role: signUpRole, account_id });

          // Dispatch login action to Redux store
          dispatch({ type: LOGIN, payload: user });

          // Redirect based on the role
          NotificationManager.success('Account created successfully! Redirecting...');
          setIsSignUpModalVisible(false);
          if (signUpRole === 'provider') {
            navigate('/app/myrequests');
          } else if (signUpRole === 'customer') {
            navigate('/app/customerrequests');
          }
        }
      }
    } catch (err) {
      console.error('Sign up error:', err);
      NotificationManager.error('Failed to sign up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Redirect to home page if user is already logged in
  if (authUser) {
    navigate('/');
    return null;
  }

  // Define the header and description based on the type
  const getHeaderText = () => {
    if (type === 'provider') {
      return 'Signup/Login to be a reviewer';
    } else if (type === 'customer') {
      return 'Login to request a service';
    }
    return 'Login';
  };

  const getFormDescription = () => {
    if (type === 'provider') {
      return 'Create an account to become a reviewer and review services.';
    } else if (type === 'customer') {
      return 'Login to access our services and place your service requests.';
    }
    return 'Please login to continue.';
  };

  return (
    <div className="fullscreen">
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={24} xl={8}>
          <Card>
            {/* Logo above the header */}
            <Row justify="center">
              <img src={logo} className="login-logo" alt="Logo" style={{ marginBottom: '15px' }} />
            </Row>

            {/* Header with title */}
            <Row justify="center">
              <Title level={3}>{getHeaderText()}</Title>
            </Row>

            {/* Form description */}
            <Row justify="center">
              <Paragraph>{getFormDescription()}</Paragraph>
            </Row>

            <Form
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="large"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: <IntlMessages id="global.email_not_valid" />,
                  },
                  {
                    required: true,
                    message: <IntlMessages id="global.email_required" />,
                  },
                ]}
              >
                <Input
                  prefix={<MailTwoTone className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="global.password_required" />,
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockTwoTone className="site-form-item-icon" />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  <IntlMessages id="global.signin" />
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => { setIsSignUpModalVisible(true); setSignUpRole('customer'); }}
                  block
                >
                  Sign Up as User
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => { setIsSignUpModalVisible(true); setSignUpRole('provider'); }}
                  block
                >
                  Sign Up as Reviewer
                </Button>
              </Form.Item>
            </Form>

            {/* Footer with contact information */}
            <Row justify="center" style={{ marginTop: 16 }}>
              <Text>For more information, please contact info@inested.com</Text>
            </Row>
          </Card>
        </Col>
      </Row>

      <Modal
        title={`Sign Up as ${signUpRole === 'provider' ? 'Reviewer' : 'User'}`}
        open={isSignUpModalVisible}
        onCancel={() => setIsSignUpModalVisible(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={handleSignUp}
          size="large"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              prefix={<MailTwoTone />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password
              prefix={<LockTwoTone />}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: 'Please input your first name!',
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>

          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
                message: 'Please input your last name!',
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>

          {signUpRole === 'provider' && (
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!',
                },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
            >
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Login;
