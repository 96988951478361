import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message, Tag, Space, Row, Card } from 'antd';
import { fetchCustomerRequests, deleteRequest } from '../../api/api';
import { supabase } from '../../utils/supabaseClient';
import RequestForm from './EditRequest';
import { useUser } from '../../utils/context';

const CustomerRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    loadRequests();
  }, [user]);

  const loadRequests = async () => {
    try {
      if (user && user.account_id) {
        const data = await fetchCustomerRequests(user.account_id);
        setRequests(data);
      }
    } catch (error) {
      message.error('Failed to fetch requests');
    } finally {
      setLoading(false);
    }
  };

  const handleRequestSaved = () => {
    setIsModalVisible(false);
    loadRequests();
  };

  const handleDeleteRequest = async (requestId) => {
    try {
      await deleteRequest(requestId);
      message.success('Request deleted successfully');
      loadRequests();
    } catch (error) {
      message.error('Failed to delete request');
    }
  };

  const showDetailModal = (record) => {
    setSelectedRequest(record);
    setIsDetailModalVisible(true);
  };
  const handlePaymentRedirect = (paymentLink) => {
    window.open(paymentLink, '_blank');
  };

  const columns = [
    {
      title: 'Service',
      dataIndex: 'service_id',
      key: 'services',
      render: (text, record) => (
        <a onClick={() => showDetailModal(record)}>
          {record?.services?.title} {record?.services?.price}$
          <br/>
          {new Date(record?.created_at).toLocaleString()}
        </a>
      ),
    },
    {
      title: 'Files',
      key: 'files',
      render: (_, record) => (
        <Space direction="vertical">
          <Card size="small" title="Customer documents" bordered={false} style={{ width: 300 }}>
            {record.files && record.files.length > 0 ? (
              record.files.map((file, index) => (
                <a
                  key={index}
                  href={`https://bgnkjonjjqmhbnefddie.supabase.co/storage/v1/object/public/requests/${file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.split('/').pop()}
                </a>
              ))
            ) : (
              <span>No files attached</span>
            )}
          </Card>
          {record.status === 'finished' && (
            <Card size="small" title="Processed documents" bordered={false} style={{ width: 300 }}>
              {record.provider_files && record.provider_files.length > 0 ? (
                record.provider_files.map((file, index) => (
                  <a
                    key={index}
                    href={`https://bgnkjonjjqmhbnefddie.supabase.co/storage/v1/object/public/requests/${record.provider_files}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {file.split('/').pop()}
                  </a>
                ))
              ) : (
                'No files attached'
              )}
              <br />
              {record?.provider_comment}
            </Card>
          )}
        </Space>
      ),
    },
    // {
    //   title: 'Reviewer',
    //   dataIndex: 'account_id',
    //   key: 'account_id',
    //   render: (text, record) =>
    //     record.account_id
    //       ? `${record?.accounts?.firstname} ${record?.accounts?.lastname}`
    //       : 'Unassigned',
    // },

    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (payment_status, record) => (
        <div style={{ verticalAlign: 'top' }}>
          <Space direction="vertical">

            {/* <Switch
              checked={payment_status === 'paid'}
              onChange={(checked) => handlePaymentStatusChange(checked, record)}
              checkedChildren="Paid"
              unCheckedChildren="Unpaid"
            /> */}


            <Tag color={payment_status === 'paid' ? 'green' : 'red'}>
              {payment_status ? payment_status.toUpperCase() : 'UNPAID'}
            </Tag>

            {/* If payment status is "Unpaid", show the payment link */}
            {payment_status !== 'paid' && record?.services?.payment_link && (
              <Button

                onClick={() => handlePaymentRedirect(record.services.payment_link)}
                type="dashed"
              >
                Pay Now
              </Button>

            )}
          </Space>
        </div>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color;
        switch (status) {
          case 'new':
            color = 'purple';
            break;
          case 'approved':
            color = 'green';
            break;
          case 'processing':
            color = 'orange';
            break;
          case 'finished':
            color = 'blue';
            break;
          default:
            color = 'default';
        }
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    // {
    //   title: 'Created date',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   render: (text, record) => (
    //     <>
    //       {new Date(record?.created_at).toLocaleString()}
    //       <br />
    //       {record.id}
    //     </>
    //   ),
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => {
              setSelectedRequest(record);
              setIsModalVisible(true);
            }}
          >
            Edit
          </Button>
          {/* <Button onClick={() => handleDeleteRequest(record.id)} danger>
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>Manage Requests</h1>
      <Button
        type="primary"
        onClick={() => {
          setSelectedRequest(null);
          setIsModalVisible(true);
        }}
      >
        New Request
      </Button>
      <Table
        columns={columns}
        dataSource={requests}
        loading={loading}
        rowKey="id"
        size="middle"
      />

      <Modal
        title={selectedRequest ? 'Edit Request' : 'New Request'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <RequestForm request={selectedRequest} onRequestSaved={handleRequestSaved} />
      </Modal>

      <Modal
        title="Request Details"
        visible={isDetailModalVisible}
        onCancel={() => setIsDetailModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsDetailModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedRequest && (
          <div>
            <p><strong>Customer Name:</strong> {selectedRequest?.customer?.firstname} {selectedRequest?.customer?.lastname}</p>
            <p><strong>Email:</strong> {selectedRequest?.customer?.email}</p>
            <p><strong>Phone:</strong> {selectedRequest?.customer?.phone}</p>
            <p><strong>Service:</strong> {selectedRequest?.services?.title}</p>
            <p><strong>Book Call:</strong> {selectedRequest.book_call ? 'Yes' : 'No'}</p>
            <p><strong>Call Date:</strong> {selectedRequest.call_date ? new Date(selectedRequest.call_date).toLocaleString() : 'N/A'}</p>
            <p><strong>Price:</strong> {selectedRequest?.services?.price}$</p>
            <p><strong>Status:</strong> {selectedRequest.status}</p>
            <p><strong>Created At:</strong> {new Date(selectedRequest.created_at).toLocaleString()}</p>
            <p><strong>Files:</strong></p>
            <ul>
              {selectedRequest.files && selectedRequest.files.length > 0 ? (
                selectedRequest.files.map((file, index) => (
                  <li key={index}>
                    <a
                      href={`https://bgnkjonjjqmhbnefddie.supabase.co/storage/v1/object/public/requests/${file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.split('/').pop()}
                    </a>
                  </li>
                ))
              ) : (
                <li>No files attached</li>
              )}
            </ul>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CustomerRequests;
