import './App.css';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import en_US from './lang/locales/en_US';
import { BrowserRouter } from 'react-router-dom';
import Wrapper from './lang';
import MainApp from './container/MainApp';
import store from './redux/store';
import { NotificationContainer } from 'react-notifications';
import './assets/css/custom.css';
import 'react-notifications/lib/notifications.css';
import { UserProvider } from './utils/context';
function App() {
  return (
    <>
      <Provider store={store}>
        <ConfigProvider
          locale={en_US}
          theme={{
            token: {
              colorPrimary: '#16bc8c',
              borderRadiusBase: '2px',
            },
            components: {
              Switch: {
                colorPrimary: '#00b96b',
              },
            },
          }}>
          <BrowserRouter>
            <Wrapper>
              <UserProvider>
                <MainApp />
              </UserProvider>
            </Wrapper>
            <NotificationContainer />
          </BrowserRouter>
        </ConfigProvider>
      </Provider>
    </>
  );
}

export default App;
