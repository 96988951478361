import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Tag,
  Typography,
  message,
} from 'antd';
import { injectIntl, useIntl } from 'react-intl';
import IntlMessages from '../../components/IntlMessage';
import PageTitle from '../../components/PageTitle';
import LayoutContent from '../../components/LayoutContent';
import { supabase } from '../../utils/supabaseClient'; // Import your Supabase client
import { useUser } from '../../utils/context';

const { Title, Text } = Typography;

const Profile = () => {
  //const user = useSelector((state) => state.auth.user); // Fetch auth user from Redux store
  const intl = useIntl();
  const formRefInfo = useRef(null); // Form reference for updating user info
  const formRefPassword = useRef(null); // Form reference for changing password

  const [loadingChangePW, setLoadingChangePW] = useState(false);
  const [loadingUpdateInfo, setLoadingUpdateInfo] = useState(false);
  const { user } = useUser();

  console.log('user profile', user);

  // Handle updating account information on Supabase
  const handleSubmitInfo = async (values) => {
    setLoadingUpdateInfo(true);
    try {
      const { error } = await supabase
        .from('accounts')
        .update({
          firstname: values.firstname,
          lastname: values.lastname,
          mobile: values.mobile,
        })
        .eq('user_id', user.id);

      if (error) throw error;

      message.success(intl.formatMessage({ id: 'global.save_success' }));
      formRefInfo.current.resetFields();
    } catch (error) {
      message.error(intl.formatMessage({ id: 'global.save_failed' }));
    } finally {
      setLoadingUpdateInfo(false);
    }
  };

  // Handle changing password using Supabase
  const handleSubmitPassword = async (values) => {
    setLoadingChangePW(true);
    try {
      const { data, error } = await supabase.auth.updateUser({
        password: values.new_password,
      });

      if (error) throw error;

      message.success(intl.formatMessage({ id: 'global.save_success' }));
      formRefPassword.current.resetFields();
    } catch (error) {
      message.error(intl.formatMessage({ id: 'global.save_failed' }));
    } finally {
      setLoadingChangePW(false);
    }
  };

  const setName = (account) => {
    if (account) {
      let name = account.fullname || `${account.firstname || ''} ${account.lastname || ''}`.trim();
      return name || intl.formatMessage({ id: 'global.user' });
    }
    return intl.formatMessage({ id: 'global.user' });
  };

  return (
    <LayoutContent>
      <PageTitle title={<IntlMessages id="global.account_information" />} />
      <Row gutter={24}>
        {/* Left Sidebar with User Info */}
        {/* <Col lg={6} sm={24}>
          <Card hoverable className="card-without-padding">
            <div className="your-img">
              <UploadAvatar />
              <Title level={3} className="your-name">
                {setName(user)}
              </Title>
              <Tag className="mt-2">{user.email}</Tag>
            </div>
          </Card>
        </Col> */}

        {/* Main Content - Account Information and Change Password */}
        <Col lg={18} sm={24}>
          <Row gutter={[24, 24]}>
            {/* Account Information */}
            {/* <Col span={24}>
              <Card hoverable>
                <Typography.Title level={4}>
                  <IntlMessages id="global.account_information" />
                </Typography.Title>

                <Form
                  ref={formRefInfo}
                  layout="vertical"
                  onFinish={handleSubmitInfo}
                  initialValues={user ? { ...user } : null}
                >
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label={<IntlMessages id="global.firstname" />}
                        name="firstname"
                        rules={[
                          {
                            required: true,
                            message: <IntlMessages id="global.requiredfield" />,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={<IntlMessages id="global.lastname" />}
                        name="lastname"
                        rules={[
                          {
                            required: true,
                            message: <IntlMessages id="global.requiredfield" />,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label={<IntlMessages id="global.email" />}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: <IntlMessages id="global.requiredfield" />,
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={<IntlMessages id="global.mobile" />}
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: <IntlMessages id="global.requiredfield" />,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loadingUpdateInfo}>
                      <IntlMessages id="global.save" />
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col> */}

            {/* Change Password */}
            <Col span={24}>
              <Card hoverable>
                <Typography.Title level={4}>
                  <IntlMessages id="global.changepassword" />
                </Typography.Title>

                <Form layout="vertical" onFinish={handleSubmitPassword} ref={formRefPassword}>
                  

                  <Form.Item
                    label={<IntlMessages id="global.new_password" />}
                    name="new_password"
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="global.requiredfield" />,
                      },
                      {
                        min: 6,
                        message: <IntlMessages id="global.password_min_charactor" />,
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label={<IntlMessages id="global.confirm_new_password" />}
                    name="confirm_new_password"
                    dependencies={['new_password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="global.requiredfield" />,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            intl.formatMessage({ id: 'global.password_not_match' })
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loadingChangePW}>
                      <IntlMessages id="global.save" />
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutContent>
  );
};

export default injectIntl(Profile);
