// src/CommentModal.js
import React, { useState } from 'react';
import { Modal, Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { supabase } from '../../utils/supabaseClient';
import { updateRequest } from '../../api/api';

const CommentModal = ({ visible, onClose, request, onCommentSubmitted }) => {
    const [fileList, setFileList] = useState([]);

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleUpload = async () => {
        const uploadedFiles = [];

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            if (file.originFileObj) {
                const filePath = `provider_files/${Date.now()}_${file.name}`;
                const { data, error } = await supabase.storage
                    .from('requests')
                    .upload(filePath, file.originFileObj);

                if (error) {
                    message.error(`Failed to upload ${file.name}: ${error.message}`);
                } else {
                    uploadedFiles.push(data.path);
                }
            }
        }

        return uploadedFiles;
    };

    const handleCommentSubmit = async (values) => {
        const uploadedFiles = await handleUpload();

        const payload = {
            provider_comment: values.provider_comment,
            provider_files: uploadedFiles,
            status:"processing"
        };

        try {
            await updateRequest(request.id, payload);
            message.success('Comment and files submitted successfully');
            onClose();
            onCommentSubmitted();
        } catch (error) {
            message.error('Failed to submit comment and files');
        }
    };

    return (
        <Modal
            title="Add Comment and Files"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Form onFinish={handleCommentSubmit} layout="vertical">
                <Form.Item
                    name="provider_comment"
                    label="Comment"
                    rules={[{ required: true, message: 'Please enter a comment' }]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    label="Upload Files"
                >
                    <Upload
                        multiple
                        beforeUpload={() => false}
                        fileList={fileList}
                        onChange={handleFileChange}
                        listType="picture"
                    >
                        <Button icon={<UploadOutlined />}>Select Files</Button>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CommentModal;
