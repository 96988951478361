// src/api.js


import { supabase } from '../utils/supabaseClient';
import { createClient } from '@supabase/supabase-js'
import { sendmail } from './mailer';

export const fetchUsers = async () => {
    const { data, error } = await supabase
        .from('auth.users')
        .select('id, email');
    if (error) {
        throw error;
    }
    return data;
};

export const createAccount = async (account) => {
    const { data, error } = await supabase
        .from('accounts')
        .insert([account]);
    if (error) {
        throw error;
    }
    return data;
};
export const fetchAccounts = async () => {
    const { data, error } = await supabase
        .from('accounts')
        .select('id, firstname, lastname, title, role, user_id, phone, created_at');
    if (error) {
        throw error;
    }
    return data;
};
// export const createRequest = async (newRequest) => {
//     const { data, error } = await supabase
//         .from('requests')
//         .insert([newRequest]);

//     if (error) {
//         throw error;
//     }
//     return data;
// };

export const createRequest = async (payload) => {
    const { data, error } = await supabase
        .from('requests')
        .insert([payload])
        .select('*') // Make sure it returns the newly inserted data
        .single();

    if (error) {
        throw error;
    }

    return data; // Return the created request
};

export const updateRequest = async (requestId, updatedData) => {
    const { data, error } = await supabase
        .from('requests')
        .update(updatedData)
        .eq('id', requestId);

    if (error) {
        throw error;
    }
    return data;
};

export const fetchServices = async () => {
    const { data, error } = await supabase
        .from('services')
        .select('id, title,price,payment_link')
        .order('title', { ascending: true });

    if (error) {
        throw error;
    }
    return data;
};


const fetchAuthUsers = async (userIds) => {

    const service_role_key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJnbmtqb25qanFtaGJuZWZkZGllIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyNDM4ODYxNSwiZXhwIjoyMDM5OTY0NjE1fQ.dx77JGKseIf-TJumhs0rcf-5WdlGawy8DNR6JFvQFTo";
    const supabase_url = "https://bgnkjonjjqmhbnefddie.supabase.co";
    const supabaseAdmin = createClient(supabase_url, service_role_key, {
        auth: {
            autoRefreshToken: false,
            persistSession: false
        }
    })
    // Access auth admin api
    //const adminAuthClient = supabase1.auth.admin

    const { data: { users }, error } = await supabaseAdmin.auth.admin.listUsers()

    if (error) {
        console.error('Error fetching users:', error);
        throw error;
    }

   // console.error('Error fetching users:', users);

    // Filter only the users that match the `user_id`s in your accounts
    return users.filter((user) => userIds.includes(user.id));
};


export const fetchAccountsWithUsers = async () => {
    // Step 1: Fetch all accounts
    const accounts = await fetchAccounts();
    //console.log('accounts', accounts);
    // Step 2: Extract user_ids
    const userIds = accounts.map((account) => account.user_id);

    // Step 3: Fetch corresponding users from Supabase Auth
    const users = await fetchAuthUsers(userIds);
    //console.log('users',users);
    // Step 4: Merge accounts with corresponding users
    const mergedData = accounts.map((account) => ({
        ...account,email: users.find((user) => user.id === account.user_id)?.email || null,
        user: users.find((user) => user.id === account.user_id) || null,
    }));

    return mergedData;
};



export const fetchRequests = async () => {
    const { data, error } = await supabase
        .from('requests')
        .select(`
      *,
      customer:customer_id (id, firstname, lastname,email),
      accounts:account_id (id, firstname, lastname,email),
      services:service_id (id, title, description, price,payment_link)
    `).order('created_at', { ascending: false });

    if (error) {
        throw error;
    }

    return data;
};

export const deleteRequest = async (requestId) => {
    const { data, error } = await supabase
        .from('requests')
        .delete()
        .eq('id', requestId);

    if (error) {
        throw error;
    }
    return data;
};


export const fetchCustomers = async () => {
    const { data, error } = await supabase
        .from('accounts')
        .select('id, firstname, lastname,email')
        .eq('role', 'customer');  // Fetch only accounts with role 'customer'

    if (error) {
        throw error;
    }

    return data;
};

export const fetchProviders = async () => {
    const { data, error } = await supabase
        .from('accounts')
        .select('id, firstname, lastname,email')
        .eq('role', 'provider');  // Fetch only accounts with role 'customer'

    if (error) {
        throw error;
    }

    return data;
};



export const fetchAssignedRequests = async (accountId) => {
   
    const { data, error } = await supabase
        .from('requests')
        .select(`
      *,
      customer:customer_id (id, firstname, lastname,email),
      accounts:account_id (id, firstname, lastname,email),
      services:service_id (id, title, description, price)
    `). eq('account_id', accountId).order('created_at', { ascending: false });

    if (error) {
        throw error;
    }

    return data;

  
};


export const fetchCustomerRequests = async (customer_id) => {

    const { data, error } = await supabase
        .from('requests')
        .select(`
      *,
      customer:customer_id (id, firstname, lastname,email),
      accounts:account_id (id, firstname, lastname,email),
      services:service_id (id, title, description, price,payment_link)
    `).eq('customer_id', customer_id).order('created_at', { ascending: false });

    if (error) {
        throw error;
    }

    return data;


};

export const fetchRequestDetail = async (request_id) => {

    const { data, error } = await supabase
        .from('requests')
        .select(`
      *,
      customer:customer_id (id, firstname, lastname,email),
      account:account_id (id, firstname, lastname,email),
      services:service_id (id, title, description, price)
    `).eq('id', request_id).single();

    if (error) {
        throw error;
    }

    return data;


};


export const updatePaymentStatus = async (requestId, newStatus) => {
    try {


        const { data, error } = await supabase
            .from('requests')
            .update({ payment_status: newStatus })
            .eq('id', requestId);

        if (error) {
            throw new Error(error.message);
        }
    } catch (error) {
        console.error('Error updating request status:', error);
        throw error;
    }
};

export const updateRequestStatus = async (requestId, newStatus) => {
    try {


        const { data, error } = await supabase
            .from('requests')
            .update({ status: newStatus })
            .eq('id', requestId);

        if (error) {
            throw new Error(error.message);
        }

        
        const request = await fetchRequestDetail(requestId);
        console.log('request', request);
        if (newStatus === 'finished') {
            
            await notifyCustomer(requestId, request.customer.email);  // Notify customer when approved
        }

        if (newStatus === 'processing') {
            await notifyAdmin(requestId, "info@inested.com");  // Notify customer when approved
        }

        return data;
    } catch (error) {
        console.error('Error updating request status:', error);
        throw error;
    }
};

const notifyAdmin = async (requestId, customerEmail) => {
    const subject = `New service request is reviewed`;
    const html = `Mew service request is reviewed. Please log in to your account to view the details.`;
    await sendmail(customerEmail, subject, html);
};

const notifyCustomer = async (requestId, customerEmail) => {
    const subject = `Your service request is completed`;
    const html = `Your service request is completed. Please log in to your account to view the details.`;
    await sendmail(customerEmail, subject, html);
};

const notifyProvider = async (requestId, customerEmail) => {
    const subject = `New service request is assigned to you`;
    const html = `New service request is assigned. Please log in to your account to view the details.`;
    await sendmail(customerEmail, subject, html);
};


export const assignRequestToProvider = async (requestId, providerId) => {
    try {
        const { data, error } = await supabase
            .from('requests') // The name of your requests table
            .update({ account_id: providerId }) // Set the account_id to the selected provider's ID
            .eq('id', requestId); // Match the request by its ID

        if (error) {
            throw error;
        }

        const request = await fetchRequestDetail(requestId);
        console.log('request', request);
        await notifyProvider(requestId,request.account.email)
        return data; // Return the updated request
    } catch (error) {
        console.error('Error assigning provider to request:', error.message);
        throw new Error('Failed to assign provider');
    }
};


