// src/Requests.js

// src/Requests.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message, Tag, Space, Row, Card } from 'antd';
import { fetchAssignedRequests, deleteRequest } from '../../api/api';
import { supabase } from '../../utils/supabaseClient';
import RequestForm from './EditRequest';
import CommentModal from './CommentModal';
import { useUser } from '../../utils/context';

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const { user } = useUser();


  useEffect(() => {
    loadRequests();
  }, [ user]);

  const loadRequests = async () => {
    try {

      // const { data: { user } } = await supabase.auth.getUser();

      // if (user) {
      //   const { data: accountData, error } = await supabase
      //     .from('accounts')
      //     .select('id,user_id')
      //     .eq('user_id', user.id)
      //     .single();

        console.log('accountData request', user);

      if (user && user.account_id) {

        const data = await fetchAssignedRequests(user.account_id);
          setRequests(data);

          console.log('assigned requests', data);
        } 
          

      
    } catch (error) {
      message.error('Failed to fetch requests'+error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestSaved = () => {
    setIsModalVisible(false);
    loadRequests();
  };

  const handleDeleteRequest = async (requestId) => {
    try {
      await deleteRequest(requestId);
      message.success('Request deleted successfully');
      loadRequests();
    } catch (error) {
      message.error('Failed to delete request');
    }
  };

  const showDetailModal = (record) => {
    setSelectedRequest(record);
    setIsDetailModalVisible(true);
  };

  const showCommentModal = (record) => {
    if(record.status === 'processing' || record.status === 'new'){
      setSelectedRequest(record);
      setIsCommentModalVisible(true);
    }
  };

  const columns = [
    // {
    //   title: 'Customer', dataIndex: 'firstname', key: 'firstname',
      
    //   render: (text, record) => {

    //     return (
    //     <>
    //         {record?.customer?.firstname} {record?.customer?.lastname}<br />
    //         {/* {record?.customer?.email}<br />
    //         {record?.customer?.phone} */}
    //       </>
    //     )

    //   }
    //  },
    
    
    {
      title: 'Service', dataIndex: 'service_id', key: 'services',
      render: (text, record) => (

        <Space direction="vertical">
          {/* <Row>
            {record?.customer?.firstname} {record?.customer?.lastname}<br />
          </Row> */}
          <Row>
        <a onClick={() => showDetailModal(record)}>
          {record?.services?.title}
          </a>
          </Row>
          <Row>
            {new Date(record?.created_at).toLocaleString()}<br />
            {record.id}
        </Row>
          </Space>
      )
    },

    {
      title: 'Files',
      key: 'files',
      render: (_, record) => (
        <Space direction="vertical">


          <Card size="small" title="Customer documents" bordered={false} style={{ width: 300 }}>
            {record.files && record.files.length > 0 ? (
              record.files.map((file, index) => (
                <a
                  key={index}
                  href={`https://bgnkjonjjqmhbnefddie.supabase.co/storage/v1/object/public/requests/${file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.split('/').pop()}
                </a>
              ))
            ) : (
              <span>No files attached</span>
            )}
          </Card>

          <Card size="small" title="Proccessed documents" bordered={false} style={{ width: 300 }}>

            {record.provider_files && record.provider_files.length > 0 ? (
              record.provider_files.map((file, index) => (
                <a
                  key={index}
                  href={`https://bgnkjonjjqmhbnefddie.supabase.co/storage/v1/object/public/requests/${record.provider_files}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.split('/').pop()}
                </a>
              ))
            ) : (
              <span>N/A</span>
            )}
            <br />
            {record?.provider_comment}

          </Card>

        </Space>
      ),
    },
    // {
    //   title: 'Assigned Account',
    //   dataIndex: 'account_id',
    //   key: 'account_id',
    //   render: (text, record) =>
    //     record.account_id
    //       ? `${record?.accounts?.firstname} ${record?.accounts?.lastname}`
    //       : 'Unassigned',
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color;
        switch (status) {
          case 'new':
            color = 'grey';
            break;
          case 'approved':
            color = 'green';
            break;
          case 'processing':
            color = 'orange';
            break;
          case 'finished':
            color = 'blue';
            break;
          default:
            color = 'default';
        }
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },

    // {
    //   title: 'Created date', dataIndex: 'created_at', key: 'created_at',

    //   render: (text, record) => {

    //     return (
    //       <> {new Date(record?.created_at).toLocaleString()}<br />
    //         {record.id}
          
    //       </>
         
    //     )
    //   }
    // },

    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => showCommentModal(record)}>Comment</Button>
        </Space>
      ),
    },

    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (_, record) => (
    //     <Space>
    //       <Button onClick={() => { setSelectedRequest(record); setIsModalVisible(true); }}>
    //         Edit
    //       </Button>
    //       <Button onClick={() => handleDeleteRequest(record.id)} danger>
    //         Delete
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div>
      <h1>Manage Requests</h1>
      {/* <Button
        type="primary"
        onClick={() => {
          setSelectedRequest(null);
          setIsModalVisible(true);
        }}>
        New Request
      </Button> */}
      <Table
        columns={columns}
        dataSource={requests}
        loading={loading}
        rowKey="id"
      />

      <CommentModal
        visible={isCommentModalVisible}
        onClose={() => setIsCommentModalVisible(false)}
        request={selectedRequest}
        onCommentSubmitted={loadRequests}
      />

      {/* <Modal
        title={selectedRequest ? 'Edit Request' : 'New Request'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}>
        <RequestForm
          request={selectedRequest}
          onRequestSaved={handleRequestSaved}
        />
      </Modal> */}


      <Modal
        title="Request Details"
        visible={isDetailModalVisible}
        onCancel={() => setIsDetailModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsDetailModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedRequest && (
          <div>
            <p><strong>Customer Name:</strong> {selectedRequest?.customer?.firstname} {selectedRequest?.customer?.lastname}</p>
            <p><strong>Email:</strong> {selectedRequest?.customer?.email}</p>
            <p><strong>Phone:</strong> {selectedRequest?.customer?.phone}</p>
            <p><strong>Service:</strong> {selectedRequest?.services?.title}</p>
            <p><strong>Book Call:</strong> {selectedRequest.book_call ? 'Yes' : 'No'}</p>
            <p><strong>Call Date:</strong> {selectedRequest.call_date ? new Date(selectedRequest.call_date).toLocaleString() : 'N/A'}</p>
            <p><strong>Price:</strong> {selectedRequest?.services?.price}$</p>
            <p><strong>Status:</strong> {selectedRequest.status}</p>
            <p><strong>Created At:</strong> {new Date(selectedRequest.created_at).toLocaleString()}</p>
            <p><strong>Files:</strong></p>
            <ul>
              {selectedRequest.files && selectedRequest.files.length > 0 ? (
                selectedRequest.files.map((file, index) => (
                  <li key={index}>
                    <a
                      href={`https://bgnkjonjjqmhbnefddie.supabase.co/storage/v1/object/public/requests/${file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.split('/').pop()}
                    </a>
                  </li>
                ))
              ) : (
                <li>No files attached</li>
              )}
            </ul>
          </div>
        )}
      </Modal>

    </div>
  );
};

export default Requests;
