import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import ConfigReducer from './ConfigReducer';
import CreateOrdersReducer from './CreateOrdersReducer';
import EditOrderReducer from './EditOrderReducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  config: ConfigReducer,
  createOrders: CreateOrdersReducer,
  editOrder: EditOrderReducer,
});

export default rootReducer;
