import React, { useState } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { supabase } from '../../utils/supabaseClient';
import { createAccount } from '../../api/api';
import { createClient } from '@supabase/supabase-js'

const { Option } = Select;

const AccountForm = ({ onAccountSaved }) => {
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const { email, password, firstname, lastname, title, role,phone } = values;

        try {
            // Step 1: Create user in Supabase Auth
            // const { data: user, error: userError } = await supabase.auth.signUp({
            //     email,
            //     password,
            // });


            const service_role_key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJnbmtqb25qanFtaGJuZWZkZGllIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyNDM4ODYxNSwiZXhwIjoyMDM5OTY0NjE1fQ.dx77JGKseIf-TJumhs0rcf-5WdlGawy8DNR6JFvQFTo";
            const supabase_url = "https://bgnkjonjjqmhbnefddie.supabase.co";
            const supabaseAdmin = createClient(supabase_url, service_role_key, {
                auth: {
                    autoRefreshToken: false,
                    persistSession: false
                }
            })


            const { data, error } = await supabaseAdmin.auth.admin.createUser({
                email: email,
                password: password,
                email_confirm:true,
                user_metadata: { name: firstname+" "+lastname }
            })

            console.log('create user', data);

            //Step 2: Create account in custom 'accounts' table
            const accountPayload = {
                user_id: data.user.id,
                firstname,
                lastname,
                title,
                role,
                phone,
                email
            };
            await createAccount(accountPayload);
            message.success('Account created successfully');
            form.resetFields();
            onAccountSaved();
        } catch (error) {
            message.error(`Failed to create account: ${error.message}`);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please input the email' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input the password' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="firstname"
                label="First Name"
                rules={[{ required: true, message: 'Please input the first name' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="lastname"
                label="Last Name"
                rules={[{ required: true, message: 'Please input the last name' }]}>
                <Input />
            </Form.Item>

            <Form.Item
                name="phone"
                label="Phone"
                rules={[{ required: false, message: 'Please input the last name' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="title"
                label="Title"
                rules={[{ required: false, message: 'Please input the title' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: 'Please select a role' }]}>
                <Select placeholder="Select a role">
                    <Option value="provider">Provider</Option>
                    <Option value="customer">Customer</Option>
                </Select>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Create Account
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AccountForm;
