import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, message, DatePicker, Switch, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { fetchServices, createRequest, updateRequest } from '../../api/api';
import dayjs from 'dayjs';
import { supabase } from '../../utils/supabaseClient';
import { useUser } from '../../utils/context';

const { Option } = Select;

const RequestForm = ({ request, onRequestSaved }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    loadData();
    if (request) {
      form.setFieldsValue({
        ...request,
        call_date: request.call_date ? dayjs(request.call_date) : null,
      });
      setFiles(request.files ? request.files.map((file, index) => ({
        uid: index,
        name: file,
        status: 'done',
        url: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/requests/${file}`,
      })) : []);
    } else {
      form.resetFields();
    }
  }, [request]);

  const loadData = async () => {
    try {
      const servicesData = await fetchServices();
      setServices(servicesData);
    } catch (error) {
      message.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = ({ fileList }) => {
    setFiles(fileList);
  };

  const handleCheckout = (paymentLink) => {
    // Append the dynamic request_id to the payment link
   // const dynamicLink = `${paymentLink}?request_id=${requestId}`;

    // Redirect to the modified payment link
    window.location.href = paymentLink;
  };


  const onFinish = async (values) => {
    const uploadedFiles = [];

    for (let file of files) {
      if (file.originFileObj) {
        const filePath = `${Date.now()}_${file.name}`;
        const { error } = await supabase.storage.from('requests').upload(filePath, file.originFileObj);
        if (!error) {
          uploadedFiles.push(filePath);
        }
      } else {
        uploadedFiles.push(file.name);
      }
    }

    const payload = {
      ...values,
      call_date: values.call_date ? values.call_date.format('YYYY-MM-DD HH:mm:ss') : null,
      files: uploadedFiles,
      customer_id: user.account_id,
      status: 'new',
    };

    try {
      let savedRequest;
      if (request) {
        await updateRequest(request.id, payload);
        message.success('Request updated successfully');
        savedRequest = request;
      } else {
        savedRequest = await createRequest(payload);
        message.success('Request created successfully');
      }

      // Get the selected service's payment link
      const selectedService = services.find(service => service.id === values.service_id);
      //console.log(savedRequest);
      if (savedRequest?.id && selectedService?.payment_link) {
        // Pass the request ID dynamically to the payment link
        const dynamicLink = `${selectedService.payment_link}?request_id=${savedRequest.id}&email=${user.email}`;
       // console.log(dynamicLink);
        handleCheckout(dynamicLink);
      }
    } catch (error) {
      message.error('Failed to save request');
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="service_id"
        label="Service"
        rules={[{ required: true, message: 'Please select a service' }]}>
        <Select placeholder="Select a service" loading={loading}>
          {services.map((service) => (
            <Option key={service.id} value={service.id}>
              {service.title} - ${service.price}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="book_call" label="Book Call" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item name="call_date" label="Call Date">
        <DatePicker showTime />
      </Form.Item>

      <Form.Item label="Upload Files">
        <Upload
          multiple
          beforeUpload={() => false}
          onChange={handleFileChange}
          defaultFileList={files}
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>Select Files</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {request ? 'Update Request' : 'Create Request'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RequestForm;
