import React, { useState } from 'react';
import { Layout, Row,Button, Space } from 'antd';
import { Link } from 'react-router-dom';
// import { UserOutlined } from '@ant-design/icons';
// import { useSelector, useDispatch } from 'react-redux';
// import { flow } from 'lodash-es';
// actions
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
// import AppFooter from './AppFooter';
import logo_white from '../assets/img/logo_white.png';
import logo_icon from '../assets/img/logo-icon.jpeg';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const { Content, Footer, Sider } = Layout;

const AppLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Space size="5">
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => onCollapse(!collapsed)}
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            width: 64,
            height: 64,
            color: 'white'
          }}
        ></Button>

        <div className="logo" justify="center" ver>
          <Link to="/">
            {collapsed ? (
             <></>
            ) : (
              <img
                style={{ height: 20, width: 'auto' }}
                src={logo_white}
                alt="Logo"
              />
            )}
          </Link>
          </div>
          </Space>
        <AppSidebar />
      </Sider>

      <Layout className="site-layout">
        {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
        <AppHeader onSiderCollapse={onCollapse} collapsed={collapsed} />
        <Content style={{ margin: '16px 16px', overflow: 'auto' }}>
          <div
            className="site-layout-background"
            style={{ padding: 16, minHeight: 480 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Inested ©2024 Created by Inested
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
