
// Function to send a custom email
export const sendmail = async (to, subject, html) => {
  const msg = {
    to,
    from: 'info@inested.com', // Use the email you verified with SendGrid
    subject,
    html, // You can send both plain text and HTML email content
  };

  await fetch('https://mailer-inky.vercel.app/api/mailer', {
    method: 'POST',
    mode: 'no-cors',
    body: JSON.stringify(msg),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.text())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
};
