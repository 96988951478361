import { Button, Result } from 'antd';
import React from 'react';

const NotFoundPage = () => {
  return (
    <>
      <div className="full-screen-center">
        <Result
          status="404"
          title="404"
          subTitle="Page not found"
          extra={
            <Button type="primary" onClick={() => this.gotoHome()}>
              Go back to the home page
            </Button>
          }
        />
        ,
      </div>
    </>
  );
};

export default NotFoundPage;
