import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message, Tag, Space, Card, Row, Select, Form, Switch } from 'antd';
import { fetchRequests, deleteRequest, updateRequestStatus, fetchProviders, assignRequestToProvider, updatePaymentStatus } from '../../api/api'; // Assuming `updatePaymentStatus` is implemented
import RequestForm from './EditRequest';

const { Option } = Select;

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);

  useEffect(() => {
    loadRequests();
    loadProviders(); // Fetch the providers data
  }, []);

  const loadRequests = async () => {
    try {
      const data = await fetchRequests();
      setRequests(data);
    } catch (error) {
      message.error('Failed to fetch requests');
    } finally {
      setLoading(false);
    }
  };

  const loadProviders = async () => {
    try {
      const data = await fetchProviders(); // Assuming fetchProviders API exists
      setProviders(data);
    } catch (error) {
      message.error('Failed to fetch providers');
    }
  };

  const handleRequestSaved = () => {
    setIsModalVisible(false);
    loadRequests();
  };

  const handleDeleteRequest = async (requestId) => {
    try {
      await deleteRequest(requestId);
      message.success('Request deleted successfully');
      loadRequests();
    } catch (error) {
      message.error('Failed to delete request');
    }
  };

  const handleApproveRequest = async (requestId) => {
    try {
      await updateRequestStatus(requestId, 'finished'); // Assuming updateRequestStatus API exists
      message.success('Request status updated to finished');
      loadRequests();
    } catch (error) {
      message.error('Failed to update request status');
    }
  };

  const handleAssignRequest = async () => {
    if (!selectedProvider) {
      message.error('Please select a provider');
      return;
    }

    try {
      await assignRequestToProvider(selectedRequest.id, selectedProvider); // Assuming assignRequestToProvider API exists
      message.success('Request assigned to provider');
      setIsAssignModalVisible(false);
      loadRequests();
    } catch (error) {
      message.error('Failed to assign provider');
    }
  };

  const handlePaymentStatusChange = async (checked, record) => {
    const newStatus = checked ? 'paid' : 'unpaid';

    try {
      await updatePaymentStatus(record.id, newStatus); // Assuming updatePaymentStatus API exists
      message.success(`Payment status updated to ${newStatus}`);
      loadRequests();
    } catch (error) {
      message.error('Failed to update payment status');
    }
  };

  const handlePaymentRedirect = (paymentLink) => {
    window.open(paymentLink, '_blank');
  };

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text, record) => (
        <div style={{ verticalAlign: 'top' }}>
          {record?.customer?.firstname} {record?.customer?.lastname}
          <br />
          {record?.customer?.email}
          <br />
          {record?.customer?.phone}
        </div>
      ),
    },
    {
      title: 'Service',
      dataIndex: 'service_id',
      key: 'services',
      render: (text, record) => (
        <div style={{ verticalAlign: 'top' }}>
          <Space direction="vertical">
            <Row><strong>{record?.services?.title} {record?.services?.price}$</strong></Row>
            <Row>{new Date(record?.created_at).toLocaleString()}</Row>
            <Row>Request ID:{record.id}</Row>
          </Space>
        </div>
      ),
    },
    {
      title: 'Files',
      key: 'files',
      render: (_, record) => (
        <Space direction="vertical">


          <Card size="small" title="Customer documents" bordered={false} style={{ width: 300 }}>
            {record.files && record.files.length > 0 ? (
              record.files.map((file, index) => (
                <a
                  key={index}
                  href={`https://bgnkjonjjqmhbnefddie.supabase.co/storage/v1/object/public/requests/${file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.split('/').pop()}
                </a>
              ))
            ) : (
              <span>No files attached</span>
            )}
          </Card>

          <Card size="small" title="Proccessed documents" bordered={false} style={{ width: 300 }}>

            {record.provider_files && record.provider_files.length > 0 ? (
              record.provider_files.map((file, index) => (
                <a
                  key={index}
                  href={`https://bgnkjonjjqmhbnefddie.supabase.co/storage/v1/object/public/requests/${record.provider_files}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.split('/').pop()}
                </a>
              ))
            ) : (
              <span>N/A</span>
            )}
            <br />
            {record?.provider_comment}

          </Card>

        </Space>
      ),
    },
    {
      title: 'Reviewer',
      dataIndex: 'account_id',
      key: 'reviewer',
      render: (account_id, record) => (
        <div style={{ verticalAlign: 'top' }}>
          <Space direction="vertical">
          {account_id ? (
            <Tag color="blue">
              {record?.accounts?.firstname} {record?.accounts?.lastname}
            </Tag>
          ) : (
            <>
              <Tag color="#f50">Unassigned</Tag>
              {/* Assign Button */}
              {record.status === 'new' && (
                    <Button size='small' onClick={() => { setSelectedRequest(record); setIsAssignModalVisible(true); }}>
                  Assign
                </Button>
              )}
            </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (payment_status, record) => (
        <div style={{ verticalAlign: 'top' }}>
          <Space direction="vertical">
           
          <Switch
            checked={payment_status === 'paid'}
            onChange={(checked) => handlePaymentStatusChange(checked, record)}
            checkedChildren="Paid"
            unCheckedChildren="Unpaid"
          />
          {/* If payment status is "Unpaid", show the payment link */}
          {payment_status !== 'paid' && record?.services?.payment_link && (
              <Button size='small'
             
              onClick={() => handlePaymentRedirect(record.services.payment_link)}
              type="dashed"
            >
              Pay Now
              </Button>
              
            )}
          </Space>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color;
        switch (status) {
          case 'new':
            color = 'grey';
            break;
          case 'approved':
            color = 'green';
            break;
          case 'processing':
            color = 'orange';
            break;
          case 'finished':
            color = 'blue';
            break;
          default:
            color = 'default';
        }
        return <Tag color={color} style={{ verticalAlign: 'top' }}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space style={{ verticalAlign: 'top' }}>
          <Button size='small' type="primary" onClick={() => { setSelectedRequest(record); setIsModalVisible(true); }}>Edit</Button>
          <Button size='small' onClick={() => handleDeleteRequest(record.id)} danger>Delete</Button>

          {record.status === 'processing' && (
            <Button size='small' onClick={() => handleApproveRequest(record.id)} type="primary">
              Approve
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>Manage Requests</h1>
      <Table
        columns={columns}
        dataSource={requests}
        loading={loading}
        rowKey="id"
        size="middle"
      />

      <Modal
        title={selectedRequest ? 'Edit Request' : 'New Request'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <RequestForm request={selectedRequest} onRequestSaved={handleRequestSaved} />
      </Modal>

      <Modal
        title="Assign Provider"
        visible={isAssignModalVisible}
        onCancel={() => setIsAssignModalVisible(false)}
        onOk={handleAssignRequest}
      >
        <Form layout="vertical">
          <Form.Item label="Select Provider">
            <Select
              placeholder="Select a provider"
              onChange={value => setSelectedProvider(value)}
              loading={loading}
            >
              {providers.map(provider => (
                <Option key={provider.id} value={provider.id}>
                  {provider.firstname} {provider.lastname} - {provider.email}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Requests;
