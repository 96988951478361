import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Typography } from 'antd';
import {
  ContactsTwoTone,
  MailOutlined,
  // MessageTwoTone,
  HomeTwoTone,
  ReconciliationTwoTone,
  DollarTwoTone,
  EnvironmentTwoTone,
} from '@ant-design/icons';
import { useIntl } from 'react-intl';
// import CardWithIcon from '../../components/CardWithIcon';
//import 'react-big-calendar/lib/css/react-big-calendar.css';

export default function Home(props) {
  const intl = useIntl();

  const authUser = useSelector((state) => state.auth.authUser);

  const cards = [
    {
      icon: <ReconciliationTwoTone />,
      title: intl.formatMessage({ id: 'global.orders' }),
      description: 'Manage your bookings and travelers',
      link: '/app/orders',
    },
   
  
    {
      icon: <ContactsTwoTone />,
      title: intl.formatMessage({ id: 'global.customers' }),
      description: 'Manage your customers',
      link: '/app/customers',
    },
    
  ];

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Col span={18}>
          <Typography.Title level={4}>
            Hi, {authUser.firstname} {authUser.lastname}
          </Typography.Title>
          <div>
            <Typography.Text type="secondary">
              <MailOutlined /> {authUser.email}
            </Typography.Text>
          </div>
        </Col>
      </Col>

      {/* <Col span={24}>
        <Col md={24} sm={24}>
          <Row gutter={[16, 24]}>
            {cards.map((card, index) => (
              <Col span={8} key={index}>
                <CardWithIcon
                  link={card.link}
                  icon={card.icon}
                  title={card.title}
                  description={card.description}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={3} sm={0}></Col>
      </Col> */}
    </Row>
  );
}
