// src/RequestForm.js
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, message, DatePicker, Switch } from 'antd';
import {
  fetchAccounts,
  fetchServices,
  createRequest,
  updateRequest,
} from '../../api/api';
import dayjs from 'dayjs';

const { Option } = Select;

const RequestForm = ({ request, onRequestSaved }) => {
  const [accounts, setAccounts] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    loadData();
    if (request) {
      form.setFieldsValue({
        ...request,
        call_date: request.call_date ? dayjs(request.call_date) : null,
      });
    } else {
      form.resetFields();
    }
  }, [request]);

  const loadData = async () => {
    try {
      const accountsData = await fetchAccounts();
      const servicesData = await fetchServices();
      setAccounts(accountsData);
      setServices(servicesData);
    } catch (error) {
      message.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      if (request) {
        await updateRequest(request.id, values);
        message.success('Request updated successfully');
      } else {
        await createRequest(values);
        message.success('Request created successfully');
      }
      form.resetFields();
      onRequestSaved();
    } catch (error) {
      message.error('Failed to save request');
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="firstname"
        label="First Name"
        rules={[{ required: true, message: 'Please input the first name' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="lastname"
        label="Last Name"
        rules={[{ required: true, message: 'Please input the last name' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: 'Please input the email' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone"
        rules={[{ required: true, message: 'Please input the phone number' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="service_id"
        label="Service"
        rules={[{ required: true, message: 'Please select a service' }]}>
        <Select placeholder="Select a service" loading={loading}>
          {services.map((service) => (
            <Option key={service.id} value={service.id}>
              {service.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="account_id" label="Assigned Account">
        <Select placeholder="Select an account" loading={loading}>
          {accounts.map((account) => (
            <Option key={account.id} value={account.id}>
              {`${account.firstname} ${account.lastname}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="book_call" label="Book Call" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name="call_date" label="Call Date">
        <DatePicker showTime />
      </Form.Item>
      <Form.Item
        name="status"
        label="Status"
        rules={[{ required: true, message: 'Please select a status' }]}>
        <Select>
          <Option value="new">New</Option>
          <Option value="approved">Approved</Option>
          <Option value="processing">Processing</Option>
          <Option value="finished">Finished</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {request ? 'Update Request' : 'Create Request'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RequestForm;
